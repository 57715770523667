import React , { useState }from 'react';
import '../styles/common.css';
import Slider from '../components/Slider/Slider';

const commentsData = [
  {
    id: 1,
    imgSrc: require('../assets/kmps_001.png'),
    text: '"Çocuğum Pırıl Kampüs’le hem eğleniyor hem de derslerini pekiştiriyor. Görsel anlatımlar sayesinde dersleri çok daha iyi anlıyor!"',
    author: 'Fatma Demir'
  },
  {
    id: 2,
    imgSrc: require('../assets/4k.jpg'),
    text: '"STEM oyunları oğlumun dikkatini çekti ve öğrenme isteğini artırdı. Pırıl Kampüs gerçekten harika bir eğitim platformu!"',
    author: 'Mert Yılmaz'
  },
  {
    id: 3,
    imgSrc: require('../assets/UI_Mini.png'),
    text: '"Öğretmen olarak öğrencilerimin gelişimini buradan takip edebilmek çok işime yarıyor. Eğitimleri hem eğitici hem de eğlenceli."',
    author: 'Selin Arslan'
  },
  {
    id: 4,
    imgSrc: require('../assets/for-parents.png'),
    text: '"Kızımın derslere olan ilgisi Pırıl Kampüs sayesinde çok arttı. Animasyonlu ders anlatımları gerçekten çok etkili!"',
    author: 'Canan Kaya'
  },
  {
    id: 5,
    imgSrc: require('../assets/UI_Mini.png'),
    text: '"Pırıl Kampüs ile çocuğumun gelişimini yakından takip ediyorum. Eğlenceli oyunlarla dolu bu platforma bayıldık!"',
    author: 'Emre Aksoy'
  }
];

const Home = () => {

  const [currentComment, setCurrentComment] = useState(0);

  const handleNext = () => {
    setCurrentComment((prevComment) =>
      prevComment === commentsData.length - 1 ? 0 : prevComment + 1
    );
  };

  const handlePrev = () => {
    setCurrentComment((prevComment) =>
      prevComment === 0 ? commentsData.length - 1 : prevComment - 1
    );
  };

  return (
    <div className="home-page">
      <Slider />
      <div className="content">

        <div className="full-width">
          
        <div>
            <h1 className="piril">Keşfet, Öğren, Eğlen!</h1>
            
              <p>Pırıl Kampüs, minik zihinler için yepyeni bir dünya! MEB müfredatına uygun, 3D animasyonlarla hazırlanan dersler ve oyunlarla dolu eğitim deneyimi sunuyoruz. Hem eğlenip hem de öğrenirken, STEM oyunları ve interaktif etkinliklerle keşif yolculuğuna çıkın! Pırıl Kampüs ile her ders, bir macera!
              </p>
              
              <button>Keşfet</button>
              </div>
        <div>
              <img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" />

            </div>
        </div>

        <div className="blank30"></div>
        <div className="stories">
            <h1 className="piril">Özellikler Buraya Gelecek</h1>
            <div>
              <div className="images"><img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" /></div>
              <div className="images"><img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" /></div>
              <div className="images"><img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" /></div>
              <div className="images"><img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" /></div>
            </div>
        </div>

        
        <div className="blank30"></div>
        <div className="full-width">
            <div>
              
            <h1 className="piril">Geleceğe Hazırlanmanın En Eğlenceli Yolu</h1>
            
              <p>Eğitim artık daha renkli ve eğlenceli! Pırıl Kampüs’te, öğrenciler hem 3D animasyonlarla dersleri pekiştiriyor hem de eğitsel oyunlarla bilgilerini sağlamlaştırıyor. Veliler ve öğretmenler ise çocuklarının gelişimini anlık olarak takip edebiliyor. Eğlence dolu öğrenme serüvenine katılmaya hazır mısınız? 
              </p>
              <button>Paketleri İncele</button>
              
            </div>
            <div>
              <img src={require('../assets/PRL_OYN_KMPS_06_YNG03_FB_00001.png')} alt="Pırıl Kampüs" />

            </div>
        </div>

        <div className="blank30"></div>

        <div className="prices">
            <h1 className="piril">SINIFLAR</h1>
            <div>

              <div className="images">
                <img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" />
                
                <h4 className="piril">Okulöncesi</h4>
                <ul>
                  <li>3-6 Yaş Arası</li>
                  <li>Oyunlar</li>
                  <li>Etkinlikler</li>
                  <li>Ev içi Etkinlikler</li>
                  <li>Ev dışı Etkinlikler</li>
                  <li>Spor Rehberi</li>
                  <li>STEM Eğzersizleri</li>
                </ul>
                <button>İNCELE</button>
              </div>

              <div className="images">
                <img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" />
                
                <h4 className="piril">1. Sınıf</h4>
                <ul>
                  <li>Animasyonlarla</li>
                  <li>Ders Anlatımı</li>
                  <li>Oyunlar</li>
                  <li>Ev içi Etkinlikler</li>
                  <li>Ev dışı Etkinlikler</li>
                  <li>Spor Rehberi</li>
                  <li>STEM Eğzersizleri</li>
                </ul>
                <button>İNCELE</button>
              </div>
              
              <div className="images">
                <img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" />
                
                <h4 className="piril">2. Sınıf</h4>
                <ul>
                <li>Animasyonlarla</li>
                  <li>Ders Anlatımı</li>
                  <li>Oyunlar</li>
                  <li>Ev içi Etkinlikler</li>
                  <li>Ev dışı Etkinlikler</li>
                  <li>Spor Rehberi</li>
                  <li>STEM Eğzersizleri</li>
                </ul>
                <button>İNCELE</button>
              </div>

              <div className="images">
                <img src={require('../assets/UI_Mini.png')} alt="Pırıl Kampüs" />
                
                <h4 className="piril">3. Sınıf</h4>
                <ul>
                <li>Animasyonlarla</li>
                  <li>Ders Anlatımı</li>
                  <li>Oyunlar</li>
                  <li>Ev içi Etkinlikler</li>
                  <li>Ev dışı Etkinlikler</li>
                  <li>Spor Rehberi</li>
                  <li>STEM Eğzersizleri</li>
                </ul>
                <button>İNCELE</button>
              </div>
               </div>
        </div>

        <div className="comments">
        <h1 className="piril">Neler Söylendi?</h1>
        <div className="comments-slider">
          <button onClick={handlePrev} className="prev"></button>
          <div className="comment-item">
            <img
              src={commentsData[currentComment].imgSrc}
              alt="Pırıl Kampüs"
            />
            <div className="images">
              <p>{commentsData[currentComment].text}</p>
              <span>{commentsData[currentComment].author}</span>
            </div>
          </div>
          <button onClick={handleNext}></button>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Home;