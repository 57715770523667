import React, { useState } from 'react';
import '../styles/common.css';
import Header from '../components/Header/Header';

const Uyelik = () => {
  // formSubmitted state'ini tanımlıyoruz
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault(); // Formun otomatik olarak gönderilmesini engelle
    setFormSubmitted(true); // Form gönderildiğinde kontrol başlasın

    const form = e.target;
    const inputs = form.querySelectorAll('input, select, textarea');
    let isValid = true;

    inputs.forEach((input) => {
      if (!input.checkValidity()) {
        isValid = false;
      }
    });

    if (isValid) {
      // Geçerli verilerle işlem yapabilirsiniz
      console.log("Form geçerli!");
    } else {
      console.log("Bazı alanlar geçersiz!");
    }
  };

  return (
    <div className="inner-page">
      <Header backgroundImage="/assets/bg/BG_COLOR_PEMBE.jpg" />
      <div className="content">
        <div className="page-width">
          <div className="page-header"><h1>Veli Üyeliği</h1><button>Öğretmen Üyeliği</button></div>
          <div className="uyelik-dual">
            <form className="uyelik-dual-form" onSubmit={handleSubmit}>
              <div className="uyelik-form">
                <div><span>Hesap Bilgileri</span></div>
                <br></br>

                <div className={`form-line ${formSubmitted && !document.getElementById('ad').checkValidity() ? 'invalid' : ''}`}>
                  <label>Ad</label><br></br>
                  <input type="text" id="ad" name="ad" placeholder='Adınızı Yazınız' required />
                </div>

                <div className={`form-line ${formSubmitted && !document.getElementById('soyad').checkValidity() ? 'invalid' : ''}`}>
                  <label>Soyad</label><br></br>
                  <input type="text" id="soyad" name="soyad" placeholder='Soyadınızı Yazınız' required />
                </div>

                <div className={`form-line ${formSubmitted && !document.getElementById('telefon').checkValidity() ? 'invalid' : ''}`}>
                  <label>Telefon</label><br></br>
                  <input type="tel" id="telefon" name="telefon" placeholder='Telefon Numaranızı Yazınız' required />
                </div>

                <div className={`form-line ${formSubmitted && !document.getElementById('email').checkValidity() ? 'invalid' : ''}`}>
                  <label>e-Posta</label><br></br>
                  <input type="email" id="email" name="email" placeholder='e-Posta Adresinizi Yazınız' required />
                </div>

              </div>


              <div className="uyelik-form">
                <div><span>Adres Bilgileri</span></div>
                <br></br>

                <div className='form-line'>
                  <label>İl</label><br></br>
                  <select id="il" name="il" required>
                    <option value="">İl Seçiniz</option>
                  </select>
                </div>
                <div className='form-line'>
                  <label>İlçe</label><br></br>
                  <select id="il" name="il" required>
                    <option value="">İlçe Seçiniz</option>
                  </select>
                </div>
                <div className='form-line'>
                  <label>Mahalle / Köy</label><br></br>
                  <select id="il" name="il" required>
                    <option value="">Mahalle/Köy Seçiniz</option>
                  </select>
                </div>

                <div className='form-line'>
                  <label>Adres</label><br></br>
                  <textarea type="text" id="ad" name="ad" placeholder='Adresin Devamını Yazınız' required></textarea>
                </div>
                <div className='form-line'>
                  <p>Tüm alanların doldurulması zorunludur.</p>
                </div>
              </div>

              <div className="uyelik-form">

              <div className="form-line">
                <input type="checkbox" className='checkbox' id="checkbox" name="checkbox" value='checkbox' required />
                <label htmlFor="checkbox"> <a href="/abonelik">Abonelik Sözleşmesini</a> okudum, anladım, onaylıyorum.</label>
              </div>

              <div className="form-line">
                <img src="/assets/UI_Mini.png" alt="captcha" /><br></br>
                <input type="text" id="captcha" name="captcha" placeholder='Güvenlik Kodu' required />
              </div>

              <div className="form-line">
                <button type="submit">Devam Et</button>
              </div>

              </div>
            </form>
          </div>
        </div>
        <div className="page-width">
          <div className="uyelik-dual">
            <div className="uyelik-form">
              <div><span>Üyelik Hakkında</span></div>
              <ul>
                <li>Pırıl Kampüs 128 bit SSL ile korunmaktadır</li>
                <li>Veli hesabı açıldıktan sonra birden çok paket satın alınabilir.</li>
                <li>Hesap sahibi, hesabına birden çok öğrenci için profil açabilir.</li>
                <li>Her bir öğrenci sadece bir pakete dahil olabilir.</li>
                <li>Önceden alınmış bir paket varsa ve öğrenciye daha sonra sınıf üyeliği tanımlanırsa iade yapılmaz.</li>
                <li>Boşta kalan üyelik tek sefere mahsus 1 Ağustosa kadar dondurulabilir ya da hesaba tanımlı başka bir öğrenciye devredilebilir.</li>
                <li>Üyelik Başka birisine devredilemez.</li>
                <li>Öğrencinin tanımlı olduğu Veli Hesabının, öğrencinin yasal Velisi olmadığı tespit edilirse hesap tamamen kilitlenir.</li>
                <li>Öğrencinin başka bir Veli hesabına aktarılması için Çağrı Merkezimiz ile irtibata geçiniz.</li>
                <li>Paketler öğrenciye TC Kimlik No üzerinden atanır.</li>

              </ul>
            </div>
            <div className="uyelik-form">
              <ul>
                <li>Adres bilgisi faturada kullanılacağı için doğru yazılmalıdır.</li>
                <li>Hatalı bilgiler paket satın alımından önce düzeltilmelidir.</li>
                <li>Paket satın alındıktan sonra bilgilerin doğruluğu kabul edilmiş sayılır.</li>
                <li><a href="/gizlilik">Gizlilik ve Güvenlik Sözleşmesi</a></li>
                <li><a href="/kvkk">KVKK Bilgilendirme</a></li>
                <li><a href="/abonelik">Abonelik Sözleşmesi</a></li>
                <li>Öğretmen hesabı açıldıktan sonra kurumdan verilen kod ile hesap etkinleştirilir.</li>
                <li>Öğrenciler toplu şekilde kurum tarafından Öğretmen Hesabına entegre edilir.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Uyelik;