import React from 'react';
import '../styles/common.css';
import Header from '../components/Header/Header';

const Neler = () => {
  return (
    <div className="inner-page">
      <Header backgroundImage="/assets/bg/BG_COLOR_MAVI.jpg" />
      <div className="content">

        <div className="page-header">
          <h1 className="piril">Neler Var?</h1>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">Çizgi Filmler ile Eğitim</h4>
            <p>"Pırıl" çizgi dizisindeki karakterler ile müfredata uygun hazırlanmış
                ders anlatımları
            </p>
            <ul>
              <li>Müfredata uygun,</li>
              <li>Sürekli güncellenen,</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">Oyunlarla Konu Alıştırması</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">Eğlenceli Oyunlar</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">Özel Hazırlanmış Etkinlikler</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">STEM Bölümü</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
        </div>


      </div>

    </div>
  );
};

export default Neler;