import React, { useEffect, useState, useRef } from 'react';
import '../../styles/common.css';
import DOMPurify from 'dompurify';

const Slider = ({ maxHeight }) => {
  const slides = [
    {
      type: 'image',
      src: require('../../assets/kmps_001.png'),
      title: 'Pırıl Kampüs\'le',
      content: 'Eğlenerek Öğren',
      buttonText: 'Paketlere Gözat',
      duration: 5000, // 5 saniye varsayılan süre
      contentPosition: 'left', // sağ, sol veya merkez
      size: 'contain'
    },
    {
      type: 'video',
      src: require('../../assets/video.mp4'),
      title: '%20 İndirim',
      content: 'Kampüs\'e Özel <br> Pırıl Sayıların Gizemi Sinema Biletlerinde ',
      buttonText: 'Bilet Al',
      duration: 22000, // 22 saniye özel süre
      contentPosition: 'right', // sağ, sol veya merkez
      size: 'cover'
    },
    {
      type: 'image',
      src: require('../../assets/4k.jpg'),
      title: 'Pırıl Kampüs\'e',
      content: 'ERKEN KAYIT AVANTAJI !',
      buttonText: 'Paketlere Gözat',
      duration: 5000, // 5 saniye varsayılan süre
      contentPosition: 'center', // sağ, sol veya merkez
      size: 'cover'
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % slides.length);
      if (slides[currentIndex].type === 'image' && videoRef.current) {
        videoRef.current.currentTime = 0;
    }

    }, slides[currentIndex].duration);
    return () => clearInterval(interval);
  }, [currentIndex, slides]);

  useEffect(() => {
    if (slides[currentIndex].type === 'video' && videoRef.current) {
      videoRef.current.play();
    }
  }, [currentIndex]);

  const handlePrev = () => {
    if (slides[currentIndex].type === 'video' && videoRef.current) {
      videoRef.current.pause();
    }
    if (slides[currentIndex].type === 'image' && videoRef.current) {
        videoRef.current.currentTime = 0;
    }
    setCurrentIndex((currentIndex - 1 + slides.length) % slides.length);
  };

  const handleNext = () => {
    if (slides[currentIndex].type === 'video' && videoRef.current) {
      videoRef.current.pause();
    }
    if (slides[currentIndex].type === 'image' && videoRef.current) {
        videoRef.current.currentTime = 0;
    }
    setCurrentIndex((currentIndex + 1) % slides.length);
  };

  document.addEventListener("visibilitychange", () => {
    const video = document.querySelector("video");
    if (document.visibilityState === "visible") {
        video.play();
    } else {
        video.pause();
    }
});


  return (
    <div className="slider" style={{ maxHeight: `${maxHeight}px` }}>
      {slides.map((slide, index) => (
        <div
          key={index}
          className="slide"
          style={{
            opacity: index === currentIndex ? 1 : 0,
            backgroundImage: slide.type === 'image' ? `url(${slide.src})` : 'none',
            '--background-size': slide.size
          }}
        >
          {slide.type === 'video' && (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
<video ref={videoRef} autoPlay muted playsInline loop>
  <source src={slide.src} type="video/mp4" />
  Tarayıcınız video etiketini desteklemiyor.
</video>

              <img src={require('../../assets/black_video_overlay.png')} alt="overlay" className="video-overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    pointerEvents: 'none',
                    mixBlendMode: 'overlay',
                    opacity: 0.3
                  }}
                  />
            </div>
          )}
          <div className={`slider-content ${slide.contentPosition}`}>
            <h1>{slide.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(slide.content) }}></p>
            <button>{slide.buttonText}</button>
          </div>
        </div>
      ))}

      <button className="slider-button prev" onClick={handlePrev}></button>
      <button className="slider-button next" onClick={handleNext}></button>

      <div className="slider-nav">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`nav-dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => {
              if (slides[currentIndex].type === 'video' && videoRef.current) {
                videoRef.current.pause();
              }
              setCurrentIndex(index);
            }}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Slider;