import React from 'react';
import '../styles/common.css';
import Header from '../components/Header/Header';

const KVKK = () => {
  return (
    <div className="inner-page">
      <Header backgroundImage="/assets/bg/BG_COLOR_MAVI.jpg" />
      <div className="content">

        <div className="page-header">
          <h1 className="piril">KVKK Bilgilendirme</h1>
        </div>


        <div className="package">
          <div className="package-content">
            <h4 className="piril">Çizgi Filmler ile Eğitim</h4>
            <p>"Pırıl" çizgi dizisindeki karakterler ile müfredata uygun hazırlanmış
                ders anlatımları
            </p>
          </div>
        </div>




      </div>

    </div>
  );
};

export default KVKK;