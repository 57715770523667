import React from 'react';
import '../../styles/common.css';

const Footer = () => {
    const handleSubscribeClick = () => {
        alert('Abone Mesajı!');
    };

    return (
        <nav className="footer">
            <div className="top-footer">

                <div className="left-footer">
                <ul>
                        <li>ATF LOGO</li>
                        <li>atf.studio</li>
                        <li>Nasıl Üye Olunur?</li>
                        <li>Kampanyalar</li>
                        <li>Sıkça Sorulan Sorular</li>                <li><a href="/gizlilik">Gizlilik ve Güvenlik Sözleşmesi</a></li>
                <li><a href="/kvkk">KVKK Bilgilendirme</a></li>
                <li><a href="/abonelik">Abonelik Sözleşmesi</a></li>
                        <li className="cagri-merkezi">Çağrı Merkezi (0850 000 00 00)</li>
                    </ul>
                </div>

                <div className="footer-logo">
                    <img src={require('../../assets/UI_Mini.png')}  alt="Pırıl Kampüs" />
                    <br></br>
                    <label htmlFor="fname">Bizden Haberdar Olun!</label><br></br><br></br>
                    <input type="text" id="eposta-piril" name="eposta-piril" placeholder='e-Posta Adresiniz'></input>
                    <br></br>
                    <button type="button" onClick={handleSubscribeClick}>Abone Ol</button>
                </div>
                <div className="footer-right">

                    <img  src={require('../../assets/PRL_OYN_KMPS_06_YNG03_FB_00001.png')} alt="Pırıl Kampüs" />
                    <br></br>
                    <div className="apps">
                        <ul>
                            <li className='piril'>Mobil Uygulama</li>
                            <li><img src={`${process.env.PUBLIC_URL}/assets/google-play.png`} alt="Google Play" /></li>
                            <li><img src={`${process.env.PUBLIC_URL}/assets/app-store.png`} alt="App Store" /></li>
                        </ul></div>
                </div>

            </div>
            <div  className="bottom-footer">
            <ul>
                        <li>Hakkımızda</li>
                        <li>Sınıflar ve Konular</li>
                        <li>Nasıl Üye Olunur?</li>
                        <li>Kampanyalar</li>
                        <li>Sıkça Sorulan Sorular</li>
                        <li>Sözleşmeler</li>
                        <li>İletişim</li>
                    </ul>
            </div>
        </nav>
    );
};

export default Footer;