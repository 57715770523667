import React from 'react';
import '../styles/common.css';
import Header from '../components/Header/Header';

const Paketler = () => {
  return (
    <div className="inner-page">
      <Header backgroundImage="/assets/bg/BG_COLOR_MAVI.jpg" />
      <div className="content">

        <div className="page-header">
          <h1 className="piril">Paketler</h1>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">Okulöncesi</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
          
          <div className="package-prices">
            <label>2.999 ₺</label>
            <button>999 ₺</button>
            <span>Son Gün 1 Haziran</span>
          </div>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">1. Sınıf</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
          
          <div className="package-prices">
            <label>2.999 ₺</label>
            <button>999 ₺</button>
            <span>Son Gün 1 Haziran</span>
          </div>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">2. Sınıf</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
          
          <div className="package-prices">
            <label>2.999 ₺</label>
            <button>999 ₺</button>
            <span>Son Gün 1 Haziran</span>
          </div>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">3. Sınıf</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
          
          <div className="package-prices">
            <label>2.999 ₺</label>
            <button>999 ₺</button>
            <span>Son Gün 1 Haziran</span>
          </div>
        </div>


        <div className="package">
          <div className="package-image">
            <img src="/assets/UI_Mini.png"></img>
          </div>
          <div className="package-content">
            <h4 className="piril">4. Sınıf</h4>
            <p>yazılar buraya gelecek sdgsdg sdg sd gsdg sd gsdg sdgsdg sdg</p>
            <ul>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
              <li>sdgsdg</li>
            </ul>
          </div>
          
          <div className="package-prices">
            <label>2.999 ₺</label>
            <button>999 ₺</button>
            <span>Son Gün 1 Haziran</span>
          </div>
        </div>


      </div>

    </div>
  );
};

export default Paketler;