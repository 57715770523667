import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Uyelik from './pages/Uyelik';
import Paketler from './pages/Paketler';
import Neler from './pages/Neler';
import Gizlilik from './pages/Gizlilik';
import KVKK from './pages/KVKK';
import Abonelik from './pages/Abonelik';
import './styles/common.css';


function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hakkimizda" element={<About />} />
          <Route path="/uyelik" element={<Uyelik />} />
          <Route path="/nelervar" element={<Neler />} />
          <Route path="/paketler" element={<Paketler />} />
          <Route path="/gizlilik" element={<Gizlilik />} />
          <Route path="/kvkk" element={<KVKK />} />
          <Route path="/abonelik" element={<Abonelik />} />
        </Routes>
        
        <Footer />
      </div>
    </Router>
  );
}

export default App;
