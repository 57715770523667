import React, { useEffect, useState } from 'react';
import '../../styles/common.css';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Menü açık mı kontrolü için state

  const handleSubscribeClick = () => {
    alert('Abone Mesajı!');
  };

  const toggleMobileMenu = () => {
    setIsMenuOpen((prevState) => !prevState); // Menü durumunu değiştir
  };

  useEffect(() => {
    const handleScroll = () => {
      const navbar = document.querySelector('.navbar');
      if (window.scrollY > 50) {
        navbar.classList.add('shrink');
      } else {
        navbar.classList.remove('shrink');
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className='desktop'>
        <a href="/"><img src={require('../../assets/UI_Mini.png')} alt="Pırıl Kampüs" /></a>

        <ul>
          <li><a href="/nelervar">Neler Var?</a></li>
          <li><a href="/paketler">Paketler</a></li>
          <li><a href="/uyelik">ÜYE OL</a></li>
          <li><button type="button" onClick={handleSubscribeClick}>GİRİŞ</button></li>
        </ul>
      </div>
      <div className='mobile'>
        <a href="/"><img src={require('../../assets/UI_Mini.png')} alt="Pırıl Kampüs" /></a>
        <ul>
          {/* Menü simgesi */}
          <li>
            <img
              src={`${process.env.PUBLIC_URL}/assets/menus.png`}
              alt="Menü"
              onClick={toggleMobileMenu}
              style={{ cursor: 'pointer' }} // İşaretçi imleç
            />
          </li>
        </ul>
        {/* Mobil menü */}
        {isMenuOpen && (
          <div className="mobile-menu">
            <ul>
              <li><a href="/nelervar">Neler Var?</a></li>
              <li><a href="/paketler">Paketler</a></li>
              <li><a href="/uyelik">ÜYE OL</a></li>
              <li><button type="button" onClick={handleSubscribeClick}>GİRİŞ</button></li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
